import { useState } from 'react';
import './seva.css';
import 'antd/dist/antd.css';
import './index.css';
import { Button } from 'antd';
import { Col, Row, Image, Carousel } from 'antd';
import events1 from './events1.jpeg';
import events2 from './events2.jpeg';
import events3 from './events3.jpeg';
import events4 from './events4.jpeg';
import events5 from './events5.jpeg';
import events6 from './events6.jpeg';


export default function HomeScreen() {

    const contentStyle = {
        padding: '2rem',
        height: '20rem',
        color: '#fff',
        lineHeight: '1rem',
        textAlign: 'left',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#364d79',
    };

    const contentStyleEvents = {
        padding: '2rem',
        color: '#fff',
        lineHeight: '1rem',
        textAlign: 'left',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const d = new Date();
    let year = d.getFullYear();
    const [tab, setTab] = useState('home');

    function changeTab(tabName) {
        setTab(tabName.toLowerCase());
    }

    function HomePage() {
        return (
            <div className='mainBody homecontent'>
            </div>
        );
    }

    function EventsPage() {
        return (
            <div className='mainBody eventcontent'>
                <Carousel autoplay='true' dotPosition='left' effect="fade">
                    <div className='centerItem' style={{ 'height': '20rem' }}>
                        <h3 style={contentStyleEvents}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h3>
                        <br />
                        <Image src={events1} width={530} height={380} />
                    </div>
                    <div className='centerItem' style={{ 'height': '20rem' }}>
                        <h3 style={contentStyleEvents}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h3>
                        <br />
                        <Image src={events2} width={530} height={380} />
                    </div>
                    <div className='centerItem' style={{ 'height': '20rem' }}>
                        <h3 style={contentStyleEvents}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h3>
                        <br />
                        <Image src={events3} width={530} height={380} />
                    </div>
                    <div className='centerItem' style={{ 'height': '20rem' }}>
                        <h3 style={contentStyleEvents}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h3>
                        <br />
                        <Image src={events4} width={530} height={380} />
                    </div>
                    <div className='centerItem' style={{ 'height': '20rem' }}>
                        <h3 style={contentStyleEvents}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h3>
                        <br />
                        <Image src={events5} width={530} height={380} />
                    </div>
                    <div className='centerItem' style={{ 'height': '20rem' }}>
                        <h3 style={contentStyleEvents}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h3>
                        <br />
                        <Image src={events6} width={530} height={380} />
                    </div>
                </Carousel>
            </div >
        );
    }

    function DonationPage() {
        return (
            <div className='mainBody donationcontent'>
                <div className="blackoverlay">
                    <Row justify="space-around" align="top">
                        <Col xs={16} >
                            <Row justify="space-around" align="top">
                                <Col xs={11}>
                                    <p style={{ "fontSize": "15px", "color": "white" }}>
                                        <b>Account Details:<br /></b>
                                        Account Name  : Seva Bahudeshiya Sanstha<br />
                                        Account Number : 35165508370<br />
                                        Bank Name : State Bank of India<br />
                                        Branch Name :  Adarsh Colony Akola<br />
                                        IFSC Code : SBIN0001932<br />
                                    </p>
                                </Col>
                                <Col xs={5} style={{ 'height': '10rem' }}>
                                    <div className='centerItem'>
                                        <lable style={{ "fontSize": "14px", "color": "white", "textAlign": "2rem", "margin-bottom": "2px" }}>
                                            Scan QR Code
                                        </lable>
                                    </div>

                                    <div style={{ 'display': 'flex', 'justify-content': 'center', 'align-items': 'center', 'height': '9rem' }}>
                                        <Image
                                            src='./scancode.png'
                                            width={150}
                                            height={150}
                                        />
                                    </div>
                                    <div className='centerItem'>
                                        <label style={{ "fontSize": "14px", "color": "white", "textAlign": "2rem" }}>
                                            UPI Id: 9822696284@SBI
                                        </label>
                                    </div>
                                </Col>
                                <Col xs={8} style={{ 'height': '10rem' }}>
                                    <lable style={{ "fontSize": "14px", "color": "white", "margin-left": "2rem" }}>

                                    </lable>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row justify="space-around" align="left">
                                <Col xs={11}>
                                    <p style={{ "fontSize": "15px", "color": "white" }}>
                                        <b>Main Head Office:</b><br />
                                        C/o Shri Vaibhav Diliprao Wankhade<br />
                                        "Renuka Sales", opposite of Nikate Hospitals,<br />
                                        Adarsha Colony,<br />
                                        Gaurakshan road, Akola,<br />
                                        Maharashtra 444 001
                                    </p>
                                </Col>
                                <Col xs={13}>
                                    <p style={{ "fontSize": "15px", "color": "white" }}>
                                        <b>Amravati Office:</b><br />
                                        C/o Shri Nandkishor Madhusudan Wankhade<br />
                                        Vedant Agencies, Vimal Nagar,<br />
                                        Farshi Stop, Chhatri Talav Road,<br />
                                        Amravati, Maharashtra 444 060<br /><br />
                                        <b>Chikhli Office:</b><br />
                                        Shri Gaurav Vilasrao Kate<br />
                                        C/o Auxicorp Consultancy Pvt.Ltd. <br />
                                        Near to Ambika Urban, <br />
                                        Behind HDFC Bank, Khandala road,<br />
                                        Tal: Chikhli, Buldhana, Maharashtra 443 201<br />
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={8}>
                            <div className='centerItem' style={{ 'height': '30rem', 'width': '22rem' }}>
                                <Carousel autoplay='true' dotPosition='left' effect="fade">
                                    <div>
                                        <h3 style={contentStyle}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h3>
                                    </div>
                                    <div>
                                        <h3 style={contentStyle}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</h3>
                                    </div>
                                    <div>
                                        <h3 style={contentStyle}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</h3>
                                    </div>
                                    <div>
                                        <h3 style={contentStyle}>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</h3>
                                    </div>
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </div >
            </div >
        );
    }

    function ContactUsPage() {
        return (
            <div className='mainBody contactuscontent'> </div>
        );
    }

    function AboutUsPage() {
        return (
            <div className='mainBody aboutuscontent'>

            </div>
        );
    }

    return (
        <div className="main">
            <div className="mainHeader">
                <Button type="link" onClick={(e) => { changeTab(e.target.outerText) }}>Home</Button>
                <Button type="link" onClick={(e) => { changeTab(e.target.outerText) }}>Events</Button>
                <Button type="link" onClick={(e) => { changeTab(e.target.outerText) }}>Donation</Button>
                <Button type="link" onClick={(e) => { changeTab(e.target.outerText) }}>Contact Us</Button>
                <Button type="link" onClick={(e) => { changeTab(e.target.outerText) }}>About Us</Button>
            </div>
            <div className="mainBody">
                {'home' === tab ? <HomePage /> : null}
                {'events' === tab ? <EventsPage /> : null}
                {'donation' === tab ? <DonationPage /> : null}
                {'contact us' === tab ? <ContactUsPage /> : null}
                {'about us' === tab ? <AboutUsPage /> : null}
            </div>
            <div className="mainFooter">
                <p>&copy; Seva Bahuudesshiya Sanstha {year}</p>
            </div>
        </div>
    )
}